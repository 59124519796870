<template>
  <div class="about-container">
    <!-- banner_about -->
    <div class="banner_about">
      <i
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_about.jpg);
        "
      ></i>
      <div class="margin width1480 hidden a-fdB">
        关于我们
        <!-- <dd>让创造更有价值</dd>
        <dl>
            <p>以充电桩为“连接器”，构建家庭能源“绿色数字”平台，为全球“双碳”目标贡献力量。</p>
            <p>成为全球电动汽车家庭充电智能硬件和绿色能源数字平台领军企业！</p>
        </dl> -->
      </div>
    </div>
    <!-- 关于挚达 -->
    <i class="md" id="d1"></i>
    <div id="a1">
      <div
        class="about_bg"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/about_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="hidden t">关于挚达</div>
          <div class="hidden f">
            <div class="l">
              <p>
                上海挚达科技发展股份有限公司成立于2010年，是全球家庭智能充电和数字能源科技领军企业。挚达科技致力于以“充电”为入口，推动新能源汽车和绿色能源进入全球家庭，为全球减排环保贡献力量。
              </p>
              <p><br /></p>
              <p>
                挚达科技入选国家级专精特新“小巨人”，是大型能源集团与新能源车企战略投资的科技创业公司。
              </p>
              <p><br /></p>
            </div>
            <ul class="r">
              <li>
                <div>
                  <dl class="l">
                    <i class="s">360</i><i class="x" id="a_s1">360</i>个
                  </dl>
                  <dl class="r">
                    截至2023年9月30日，已覆盖全国超过360个城市。
                  </dl>
                </div>
              </li>
              <li>
                <div>
                  <dl class="l">
                    <i class="s">20.5</i><i class="x" id="a_s2">20.5</i>%
                  </dl>
                  <dl class="r">
                    根据弗若斯特沙利文的资料，于2021.1-2023.9期间，按家用电动汽车充电桩销量计，我们的中国市场占有率达20.5%，全球市场占有率达到12.2%。
                  </dl>
                </div>
              </li>
              <li>
                <div>
                  <dl class="l">
                    <i class="s">90</i><i class="x" id="a_s4">90</i>万
                  </dl>
                  <dl class="r">
                    于2021.1-2023.9期间，我们全球已累计出货90万台家用电动汽车充电桩及在中国已累计出货80万台家用电动汽车充电桩。
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- about_tp -->
    <div id="a2">
      <div
        class="about_tp"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/about_tp.jpg);
        "
      >
        <div class="margin width1480 hidden">充电 · 懂车 · 爱生活</div>
      </div>
    </div>
    <!-- 发展历史 -->
    <div id="a3">
      <div class="about_fzls">
        <i class="md" id="d2"></i>
        <div class="margin width1480 hidden">
          <div class="t">发展历史</div>
          <div class="f">
            <ul class="l">
              <li>
                <span>2024</span>
                <dl>
                  <p>进行规模化海外部署，落地第一个海外工厂-泰国工厂。</p>
                  <p>
                    正式推出方挚达家庭绿色数字能源解决案，电动车充绿电，环保又省钱。
                  </p>
                </dl>
              </li>
              <li>
                <span>2023</span>
                <dl>
                  <p>收购自动充电机器人行业领导企业享奕科技。</p>
                </dl>
              </li>
              <li>
                <span>2022</span>
                <dl>
                  <p>获中国最大的新能源汽车品牌比亚迪战略投资。</p>
                  <p>年产200万套车规级充电桩的二期生产基地落户安庆。</p>
                  <p>
                    完成股份制改革，正式更名为“上海挚达科技发展股份有限公司”。
                  </p>
                </dl>
              </li>
              <li>
                <span>2021</span>
                <dl>
                  <p>中国最大的新能源企业国家电投入股挚达。</p>
                  <p>荣获国家级专精特新小巨人殊荣。</p>
                  <p>登录各大电商平台，网销第一。</p>
                </dl>
              </li>
              <li>
                <span>2020</span>
                <dl>
                  <p>
                    成功发布全球标智能充电产品：金刚，获2020年中国数字经济科技创新明星产品奖。
                  </p>
                  <p>
                    成为比亚迪原厂充电桩供应商和官方安装服务商、海外独家提供商。
                  </p>
                </dl>
              </li>
              <!-- <li>
                <span>2019</span>
                <dl>我们被上海市杨浦区人民政府评为“双创小巨人企业”。</dl>
              </li> -->
              <li>
                <span>2018</span>
                <dl>成为上汽大众原厂充电桩供应商和官方安装服务商。</dl>
              </li>
              <li>
                <span>2017</span>
                <dl>在无锡成立数字化平台公司，专注家庭数字化平台研发。</dl>
              </li>
              <li>
                <span>2016</span>
                <dl>
                  成功发布首款家庭智能充电产品：守望者系列，获CIDI工业设计奖。
                </dl>
              </li>
              <li>
                <span>2015</span>
                <dl>
                  <p>德国电动出行协会BEM在挚达设置中国代表处。</p>
                  <p>成为宝马官方安装服务商。</p>
                  <p>第一期车规级充电桩生产基地落户安徽宣城。</p>
                </dl>
              </li>
              <li>
                <span>2014</span>
                <dl>
                  <p>成为特斯拉首批签约安装服务商。</p>
                  <p>成为保时捷官方安装服务商。</p>
                </dl>
              </li>
              <li>
                <span>2011</span>
                <dl>开发第一代电动汽车分时共享平台。</dl>
              </li>
              <li>
                <span>2010</span>
                <dl>成立于上海市杨浦区。</dl>
              </li>
            </ul>
            <div class="flexslider">
              <ul class="slides">
                <li v-for="(jx, jxi) in state.jxDataList" :key="jxi">
                  <dl>
                    <img :src="jx.imgUrl" />
                  </dl>
                  <ol>
                    荣誉资质
                  </ol>
                  <dd>{{ jx.fileName }}</dd>
                </li>
              </ul>
              <dl class="sl">
                <ol>
                  <p><i>/</i>1</p>
                  <p><i>/</i>10</p>
                  <p><i>/</i>11</p>
                  <p><i>/</i>12</p>
                  <p><i>/</i>14</p>
                  <p><i>/</i>13</p>
                  <p><i>/</i>15</p>
                  <p><i>/</i>16</p>
                  <p><i>/</i>17</p>
                  <p><i>/</i>18</p>
                  <p><i>/</i>19</p>
                  <p><i>/</i>2</p>
                  <p><i>/</i>20</p>
                  <p><i>/</i>66</p>
                  <p><i>/</i>67</p>
                  <p><i>/</i>21</p>
                  <p><i>/</i>22</p>
                  <p><i>/</i>23</p>
                  <p><i>/</i>24</p>
                  <p><i>/</i>25</p>
                  <p><i>/</i>26</p>
                  <p><i>/</i>27</p>
                  <p><i>/</i>28</p>
                  <p><i>/</i>28</p>
                  <p><i>/</i>29</p>
                  <p><i>/</i>3</p>
                  <p><i>/</i>30</p>
                  <p><i>/</i>31</p>
                  <p><i>/</i>32</p>
                  <p><i>/</i>33</p>
                  <p><i>/</i>34</p>
                  <p><i>/</i>35</p>
                  <p><i>/</i>35</p>
                  <p><i>/</i>36</p>
                  <p><i>/</i>37</p>
                  <p><i>/</i>36</p>
                  <p><i>/</i>38</p>
                  <p><i>/</i>39</p>
                  <p><i>/</i>39</p>
                  <p><i>/</i>4</p>
                  <p><i>/</i>40</p>
                  <p><i>/</i>41</p>
                  <p><i>/</i>42</p>
                  <p><i>/</i>43</p>
                  <p><i>/</i>44</p>
                  <p><i>/</i>45</p>
                  <p><i>/</i>46</p>
                  <p><i>/</i>47</p>
                  <p><i>/</i>48</p>
                  <p><i>/</i>49</p>
                  <p><i>/</i>5</p>
                  <p><i>/</i>50</p>
                  <p><i>/</i>51</p>
                  <p><i>/</i>52</p>
                  <p><i>/</i>53</p>
                  <p><i>/</i>54</p>
                  <p><i>/</i>55</p>
                  <p><i>/</i>56</p>
                  <p><i>/</i>57</p>
                  <p><i>/</i>58</p>
                  <p><i>/</i>59</p>
                  <p><i>/</i>6</p>
                  <p><i>/</i>60</p>
                  <p><i>/</i>61</p>
                  <p><i>/</i>62</p>
                  <p><i>/</i>63</p>
                  <p><i>/</i>64</p>
                  <p><i>/</i>65</p>
                  <p><i>/</i>7</p>
                  <p><i>/</i>8</p>
                  <p><i>/</i>9</p>
                  <p><i>/</i>68</p>
                  <p><i>/</i>69</p>
                  <p><i>/</i>70</p>
                </ol>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- about_qh -->
    <div id="a4" v-viewer>
      <div class="ck-slide">
        <i class="md" id="d3"></i>
        <ul class="ck-slide-wrapper">
          <li
            class="l1 on"
            style="
              background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/about_qh_bg.jpg);
            "
          >
            <div class="width1480 hidden margin">
              <div class="tp xt1">
                <div class="tit">
                  <span><i></i></span>10万级无尘净化PCBA贴片车间
                </div>
                <div class="bg">
                  <img
                    src="https://img.cdn.wxzhida.cn/shzd-web-site/images/fact-view-01.jpg"
                  />
                </div>
              </div>
              <div class="tp xt2">
                <div class="tit">
                  <span><i></i></span>国内首家智能化和数字化装配车间
                </div>
                <div class="bg">
                  <img
                    src="https://img.cdn.wxzhida.cn/shzd-web-site/images/fact-view-02.jpg"
                  />
                </div>
              </div>
              <div class="tp xt4">
                <div class="tit">
                  <span><i></i></span>产品老化测试系统
                </div>
                <div class="bg">
                  <img
                    src="https://img.cdn.wxzhida.cn/shzd-web-site/images/fact-view-03.jpg"
                  />
                </div>
              </div>
            </div>
          </li>
          <li
            class="l2"
            style="
              background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/about_qh_bg.jpg);
            "
          >
            <div class="width1480 hidden margin"></div>
          </li>
        </ul>
        <div class="width1480 hidden margin wzsm">
          <div class="l">
            <dd>研发生产</dd>
            <dl>
              <p>
                有着一支训练有素，经验丰富的工程队伍和先进的设备，我们的研发能力，能满足客户的需求。
              </p>
              <p>设计出令人满意的工作和成本效益的解决方案是我们的追求。</p>
              <p>
                从创建新思路采样，从设计到批量生产，我们的专业人员投入到每一个阶段。
              </p>
            </dl>
          </div>
        </div>
        <div class="ck-slidebox">
          <div class="margin width1480 hidden">
            <a href="javascript:;" class="ctrl-slide ck-prev"
              ><i class="fa fa-angle-left"></i
            ></a>
            <a href="javascript:;" class="ctrl-slide ck-next"
              ><i class="fa fa-angle-right"></i
            ></a>
          </div>
        </div>
        <div class="ck-slidebox qh_dj">
          <div class="slideWrap margin width1480">
            <ul class="dot-wrap">
              <li class="current">安徽宣城生产线<i></i></li>
              <li>安徽安庆生产研发基地<i></i></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="about_qh">
      <ul class="slides">
        <li
          style="
            background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/about_qh_bg.jpg);
          "
        >
          <div class="bg">
            <dd>研发生产</dd>
            <dl>
              <p>
                有着一支训练有素，经验丰富的工程队伍和先进的设备，我们的研发能力，能满足客户的需求。
              </p>
              <p>设计出令人满意的工作和成本效益的解决方案是我们的追求。</p>
              <p>
                从创建新思路采样，从设计到批量生产，我们的专业人员投入到每一个阶段。
              </p>
            </dl>
          </div>
        </li>
        <li
          style="
            background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/about_qh_bg.jpg);
          "
        >
          <div class="bg">
            <dd>研发生产2</dd>
            <dl>
              <p>
                有着一支训练有素，经验丰富的工程队伍和先进的设备，我们的研发能力，能满足客户的需求。
              </p>
              <p>设计出令人满意的工作和成本效益的解决方案是我们的追求。</p>
              <p>
                从创建新思路采样，从设计到批量生产，我们的专业人员投入到每一个阶段。
              </p>
            </dl>
          </div>
        </li>
      </ul>
    </div>

    <!-- <div class="margin width1480 fact-view-title-div">
      <div class="left-fact-title-div">
        <div class="fact-title-child-div">1) 10万级无尘净化PCBA贴片车间</div>
        <div class="fact-title-child-div">2) 国内首家智能化和数字化装配车间</div>
        <div class="fact-title-child-div">3) 产品老化测试系统</div>
      </div>

      <div class="right-flex-img-div">
        <div class="flex-div">
          <div class="img-child-div">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/fact-view-01.jpg"
              class="factory-view-img"
            />
          </div>
          <div class="img-child-div">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/fact-view-02.jpg"
              class="factory-view-img"
            />
          </div>
        </div>
        <div class="flex-div">
          <div class="img-child-div">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/fact-view-03.jpg"
              class="factory-view-img"
            />
          </div>
          <div class="img-child-div">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/fact-view-04.jpg"
              class="factory-view-img"
            />
          </div>
        </div>
      </div>
    </div> -->

    <!-- 服务能力 -->
    <div id="a5">
      <div class="margin width1480 hidden about_fwnl">
        <i class="md" id="d4"></i>
        <dd>服务能力</dd>
        <dl>
          根据弗若斯特沙利文的资料，我们建立了中国最大的充电桩服务网络，提供上门安装及售后服务，截至2023年9月30日，已覆盖全国超过360个城市。
        </dl>
      </div>
      <!-- about_fwnl_bg -->
      <div class="margin width1480 hidden about_fwnl_bg">
        <ul>
          <li
            style="
              background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/about_fwnl1.jpg);
            "
          >
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/about_fwnl_tb1.png"
                />
              </ol>
              <div class="f">
                <dd>服务优势</dd>
                <dl>
                  10年充电服务经验，累计服务数十万名新能源车主， 服务高效可靠
                </dl>
              </div>
            </div>
          </li>
          <li
            style="
              background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/about_fwnl2.jpg);
            "
          >
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/about_fwnl_tb2.png"
                />
              </ol>
              <div class="f">
                <dd>施工能力</dd>
                <dl>
                  目前，挚达在全国各地共有超过140家下属安装服务商，可迅速响应全国各地的安装服务，并能确保特殊情况12小时内上门处理的能力。
                </dl>
              </div>
            </div>
          </li>
          <li
            style="
              background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/about_fwnl3.jpg);
            "
          >
            <div class="bg">
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/about_fwnl_tb3.png"
                />
              </ol>
              <div class="f">
                <dd>服务经验</dd>
                <dl>
                  团队成员中，76%的人员在充电安装服务有4年以上的经验，精通于安装流程、质量、客服沟通、应急及投诉等问题的处理。
                </dl>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";

import config from "../../libs/config";

export default {
  name: "About",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();

    const store = useStore();

    const state = reactive({
      jxDataList: config.jxDataList,
    });

    watch(
      () => store.state.curPageTarget,
      (newValue, oldValue) => {
        console.log("值发生了变更", newValue, oldValue);
        const jumpToTarget = newValue;
        if (jumpToTarget) {
          document.querySelector("#" + jumpToTarget).scrollIntoView({
            behavior: "smooth",
            // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
            block: "start",
            // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
            inline: "nearest",
            // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
          });
        }
      },
      { deep: true }
    );

    onMounted(() => {
      document.title = "关于我们";

      // 滚动到页面最上方
      window.scrollTo(0, 0);

      const jumpToTarget = proxy.$route.query.pagePostion;
      if (jumpToTarget) {
        document.querySelector("#" + jumpToTarget).scrollIntoView({
          behavior: "smooth",
          // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
          block: "start",
          // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
          inline: "nearest",
          // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
        });
      }

      $(".about_qh").flexslider({
        animation: "slide",
        start: function (slider) {
          $("body").removeClass("loading");
        },
      });

      var options = {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      };

      var options2 = {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        decimalPlaces: ".",
        prefix: "",
      };

      var demo = new CountUp("a_s1", 0, 360, 0, 2, options);
      var demo2 = new CountUp("a_s2", 0, 20.5, 1, 3, options2);
      // var demo3 = new CountUp('a_s3', 0, 27.7, 1, 3, options2)
      var demo4 = new CountUp("a_s4", 0, 90, 0, 6, options);

      $(window).scroll(function () {
        if ($(window).scrollTop() >= 300) {
          demo.start();
          demo2.start();
          // demo3.start()
          demo4.start();
        }
      });

      $(".flexslider").flexslider({
        animation: "slide",
        start: function (slider) {
          $("body").removeClass("loading");
        },
      });

      $(".ck-slide").ckSlide({
        autoPlay: false, //true
      });

      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop(); //滚动条距离顶端值
        for (var i = 1; i < 10; i++) {
          if ($("#a" + i) && $("#a" + i).offset()) {
            //加循环
            if ($("#a" + i).offset().top <= wst) {
              //判断滚动条位置
              $("#a" + i).addClass("on x"); //给当前导航加c类
            } else {
              //$("#a"+i).removeClass('on');
              $("#a" + i).removeClass("x");
            }
          }
        }
      });

      console.log(state.jxDataList);
    });

    return {
      state,
    };
  },
};
</script>

<style scoped>
.fact-view-title-div {
  display: flex;
}

.right-flex-img-div {
  margin-top: 50px;
}

.fact-title-child-div {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1.125rem;
}

.flex-div {
  display: flex;
}

.left-fact-title-div {
  margin-right: 50px;
  margin-top: 120px;
  flex: 1;
}

.img-child-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
}

.factory-view-img {
  width: 500px;
}
</style>
